import A11yDialog from 'a11y-dialog';
import axios from 'axios';

window.addEventListener('load', () => {

    const playerModalElement = document.getElementById('playerModal');

    if(playerModalElement) {
        const playerModal = new A11yDialog(playerModalElement);

        document.querySelectorAll('.player').forEach(playerElement => {
            ['click', 'touch'].forEach(action => {
                playerElement.addEventListener(action, handleTeamClick);
            })
        })

        function handleTeamClick() {
            const playerSlug = this.getAttribute('data-player');

            console.log('Clicked!');

            axios.get('/the-team-players/' + playerSlug).then(response => {
                playerModalElement.querySelector('#playerModalContent').innerHTML = response.data;

                playerModal.show();
            });

        }
    }
});