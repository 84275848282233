import Swiper from 'swiper/bundle';

const Countdown = require('countdown-js');

window.addEventListener('load', () => {
    const swiper = new Swiper('.swiper-container', {
        slidesPerView: 1,
        effect: 'slide',
        loop: true,
        speed: 1000,
        autoplay: {
            delay: 4500,
            disableOnInteraction: false,
        },
    });

    const countdownWrapper = document.querySelector('.next-match-container .countdown[data-countdown]');

    if(countdownWrapper) {
        Countdown.timer(new Date(countdownWrapper.getAttribute('data-countdown') * 1000), (time) => {
            countdownWrapper.querySelector('.days').innerHTML = time.days;
            countdownWrapper.querySelector('.hours').innerHTML = time.hours;
            countdownWrapper.querySelector('.minutes').innerHTML = time.minutes;
            countdownWrapper.querySelector('.seconds').innerHTML = time.seconds;
        }, () => {
            console.log('Complete!!!!');
        });
    }



});